import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import GameContainer from "./components/GameContainer";
import './App.css';
import { Amplify, API } from 'aws-amplify'
import awsconfig from './aws-exports'
import { useLoaderData, useParams } from 'react-router-dom';
import { TooEarlyForPuzzle } from './components/ErrorPage';
import ArchiveControls from './components/ArchiveControls';

Amplify.configure(awsconfig)

const games = {
  0: [9, 3, 1, 2, 5, 3, 6, 9, 7],
  1: [1, 3, 4, 4, 6, 7, 7, 3, 1],
  2: [6, 3, 1, 9, 5, 3, 2, 9, 7],
  3: [9, 2, 1, 2, 4, 3, 6, 8, 7],
  4: [2, 3, 1, 7, 9, 8, 3, 9, 6],
  5: [4, 5, 2, 4, 5, 4, 6, 7, 5],
  6: [6, 4, 5, 3, 8, 1, 6, 9, 3],
  7: [3, 1, 6, 5, 4, 9, 4, 3, 1],
  8: [7, 5, 6, 3, 8, 1, 6, 9, 3],
  9: [6, 5, 6, 2, 8, 1, 5, 9, 3],
  10: [5, 8, 3, 2, 5, 1, 6, 9, 6],
  11: [3, 2, 3, 3, 6, 2, 5, 8, 4],
  12: [5, 7, 2, 3, 6, 2, 5, 7, 8], // 9 
  13: [1, 2, 7, 1, 2, 1, 3, 2, 8], // 7 
  14: [2, 5, 6, 8, 2, 8, 1, 1, 9], // 9 
  15: [7, 2, 2, 3, 4, 6, 7, 6, 8], // 9
  16: [8, 9, 6, 6, 7, 1, 9, 2, 3], //8
  17: [1, 4, 7, 1, 1, 6, 7, 3, 3], // 10
  18: [3, 5, 1, 4, 5, 2, 8, 4, 4], // 9 
  19: [9, 6, 1, 8, 5, 7, 6, 4, 2], // 9
  20: [4, 2, 4, 9, 3, 9, 5, 1, 2], // 9 WRONGO
  21: [1, 8, 3, 7, 7, 2, 8, 9, 6], // 9
  22: [2, 2, 8, 3, 4, 2, 1, 2, 6], // 9
  23: [7, 2, 2, 9, 6, 7, 3, 6, 3], // 11
  24: [3, 5, 4, 4, 3, 2, 7, 9, 5], // 9
  25: [1, 6, 9, 1, 7, 7, 3, 9, 8], // 8
  26: [6, 5, 6, 4, 5, 5, 7, 9, 7], //7
  27: [5,7,2,8,4,6,3,1,3], // 10
  28: [5,1,5,4,3,1,5,2,4], // 7
  29: [9,2,1,5,1,5,9,7,6], // 10
  30: [4,8,3,8,1,3,1,8,9], // 8
  31: [1,8,6,2,5,1,3,2,8], // 10
  32: [2,3,3,6,5,1,4,5,1], // 9
  33: [5,6,9,3,7,3,6,8,1], // 11
  34: [1,4,5,4,5,2,1,4,7], // 9?
  35: [1,3,3,3,9,4,9,8,8], // 9
  36: [9,2,2,3,7,6,2,7,1], // 10
  37: [2,3,9,2,1,1,5,2,5], // 8
  38: [2, 3, 3, 3, 9, 4, 6, 6, 6], // 9
  39: [2, 1, 8, 2, 3, 4, 6, 2, 8], // 9
  40: [3, 1, 7, 2, 1, 9, 1, 3, 3], // 9
  41: [3, 7, 8, 5, 5, 3, 2, 5, 7], // 8
  42: [3, 5, 4, 5, 4, 4, 3, 1, 7], // 9
  43: [5, 7, 9, 9, 1, 3, 8, 5, 1], // 10
  44: [6, 3, 8, 2, 6, 8, 6, 5, 4], // 8
  45: [7, 1, 1, 2, 1, 3, 2, 8, 5], // 9
  46: [5, 7, 3, 4, 5, 6, 8, 5, 5], // 7
  47: [9, 2, 2, 2, 4, 9, 2, 1, 8], // 8
  48: [1, 8, 2, 3, 2, 6, 2, 8, 1], // 9
  49: [9, 9, 7, 1, 4, 5, 5, 1, 4], // 10
  50: [1, 1, 2, 9, 2, 2, 8, 5, 3], // 9
  51: [1, 2, 9, 4, 8, 8, 9, 3, 4], // 9
  52: [3, 6, 8, 2, 2, 3, 5, 3, 4], // 9
  53: [4, 4, 7, 9, 6, 5, 3, 4, 6], // 8
  54: [5, 3, 9, 5, 1, 6, 6, 3, 1], // 9
  55: [6, 2, 1, 8, 7, 7, 8, 2, 7], // 7
  56: [2, 8, 1, 4, 6, 8, 7, 9, 6], // 9
  57: [2, 2, 8, 8, 1, 8, 4, 5, 1], // 9
  58: [5, 8, 3, 6, 3, 2, 6, 9, 9], // 9
  59: [1, 7, 2, 7, 2, 4, 1, 7, 8], // 10
  60: [5, 9, 7, 9, 9, 1, 8, 2, 1], // 8
  61: [8, 5, 9, 2, 4, 3, 4, 4, 9], // 9
  62: [7, 1, 2, 1, 3, 4, 8, 2, 2], // 8
  63: [1, 1, 7, 6, 9, 7, 2, 9, 6], // 9
  64: [4, 1, 9, 2, 1, 9, 5, 2, 9], // 7
  65: [7, 8, 8, 7, 2, 9, 2, 6, 2], // 8
  66: [1, 7, 9, 4, 8, 8, 9, 6, 2], // 9
  67: [1, 4, 4, 9, 8, 4, 5, 3, 4], // 9
  68: [1, 5, 2, 3, 7, 4, 1, 9, 7], // 10
  69: [9, 9, 3, 3, 1, 3, 3, 7, 4], // 9
  70: [3, 3, 1, 2, 4, 1, 8, 2, 9], // 7
  71: [5, 3, 5, 2, 1, 6, 7, 3, 7], // 9
  72: [6, 4, 6, 9, 7, 2, 7, 8, 5], // 9
  73: [5, 9, 3, 8, 2, 7, 3, 2, 3], // 9
  74: [1, 4, 9, 4, 9, 6, 2, 4, 9], // 11
  75: [8, 1, 6, 9, 2, 9, 7, 2, 7], // 7
  76: [9, 6, 6, 3, 5, 4, 1, 3, 5], // 9
  77: [8, 9, 5, 6, 7, 3, 2, 5, 3], // 9
  78: [9, 6, 7, 9, 2, 1, 1, 7, 3], // 9
  79: [1, 6, 2, 7, 6, 6, 6, 7, 1], // 8
  80: [8, 7, 6, 3, 6, 7, 3, 2, 3], // 10
  81: [6, 6, 9, 6, 6, 8, 3, 2, 5], // 9
  82: [2, 7, 5, 5, 1, 1, 8, 7, 9], // 9
  83: [9, 5, 9, 9, 7, 8, 1, 4, 5], // 9
  84: [1, 1, 7, 5, 5, 2, 6, 7, 8], // 9
  85: [6, 2, 2, 2, 1, 6, 7, 9, 1], // 8
  86: [5, 3, 7, 5, 6, 3, 3, 3, 1], // 9
  87: [5, 5, 2, 2, 8, 9, 7, 3, 7], // 11
  88: [5, 9, 5, 1, 2, 8, 8, 2, 5], // 9
  89: [3, 7, 9, 9, 3, 5, 7, 8, 9], // 9
  90: [6, 7, 1, 6, 2, 9, 1, 3, 1], // 8
  91: [1, 9, 2, 6, 4, 8, 9, 5, 7], // 10
  92: [3, 1, 2, 9, 9, 7, 9, 7, 7], // 9
  93: [6, 9, 1, 2, 8, 8, 8, 3, 9], // 9
  94: [3, 5, 3, 9, 5, 7, 4, 9, 3], // 9
  95: [3, 3, 3, 7, 8, 5, 4, 9, 6], // 9
  96: [9, 6, 4, 6, 6, 8, 5, 6, 7], // 8
  97: [2, 3, 8, 6, 2, 8, 7, 6, 3], // 10
  98: [7, 9, 1, 5, 6, 5, 5, 7, 6], // 8
  99: [9, 9, 8, 3, 1, 1, 7, 1, 9], // 8
  100: [3, 9, 4, 3, 1, 4, 9, 2, 7], // 9
  101: [5, 8, 7, 7, 7, 5, 4, 6, 2], // 9
  102: [9, 9, 2, 9, 2, 1, 2, 8, 6], // 9
  103: [7, 4, 7, 5, 2, 2, 3, 7, 2], // 10
  104: [3, 2, 4, 3, 7, 5, 7, 6, 5], // 8
  105: [2, 1, 1, 8, 1, 7, 3, 1, 6], // 9
  106: [5, 8, 8, 8, 9, 5, 8, 4, 8], // 7
  107: [2, 8, 8, 1, 5, 7, 4, 5, 2], // 9
  108: [6, 3, 8, 2, 2, 9, 7, 5, 9], // 10
  109: [9, 1, 5, 3, 9, 1, 3, 8, 9], // 8
  110: [6, 8, 4, 6, 5, 5, 6, 7, 1], // 9
  111: [1, 1, 8, 5, 9, 8, 9, 3, 4], // 9
  112: [2, 6, 5, 3, 3, 6, 6, 7, 7], // 7
  113: [7, 1, 6, 4, 1, 3, 5, 4, 5], // 9
  114: [4, 6, 1, 8, 8, 5, 4, 9, 3], // 11
  115: [6, 6, 2, 2, 9, 1, 9, 8, 2], // 9
  116: [9, 5, 9, 1, 1, 3, 1, 9, 1], // 9
  117: [7, 3, 5, 3, 9, 3, 4, 5, 9], // 9
  118: [9, 3, 9, 7, 7, 9, 4, 7, 2], // 9
  119: [8, 4, 4, 7, 4, 8, 7, 6, 6], // 8
  120: [6, 4, 9, 1, 7, 2, 8, 2, 9], // 10
  121: [3, 3, 6, 4, 6, 3, 6, 3, 5], // 7
  122: [1, 6, 9, 9, 3, 7, 8, 1, 4], // 9
  123: [5, 9, 7, 4, 1, 5, 6, 9, 8], // 9
  124: [9, 9, 4, 8, 6, 7, 3, 4, 7], // 9
  125: [4, 1, 8, 4, 7, 2, 2, 2, 6], // 9
  126: [1, 4, 9, 3, 7, 6, 2, 5, 8], // 11
  127: [8, 2, 4, 3, 2, 5, 2, 2, 5], // 9
  128: [9, 6, 8, 2, 3, 9, 8, 7, 5], // 9
  129: [7, 3, 2, 8, 8, 3, 4, 6, 4], // 10
  130: [1, 9, 1, 3, 7, 7, 1, 8, 2], // 9
  131: [2, 1, 5, 8, 8, 4, 1, 8, 2], // 9
  132: [1, 7, 1, 8, 5, 6, 7, 1, 6], // 9
  133: [3, 4, 3, 2, 8, 3, 9, 1, 9], // 7
  134: [5, 8, 7, 6, 1, 2, 6, 7, 9], // 9
  135: [8, 9, 4, 6, 6, 4, 5, 7, 5], // 9
  136: [2, 5, 2, 8, 3, 5, 5, 2, 7], // 11
  137: [9, 1, 2, 9, 7, 1, 7, 1, 5], // 9
  138: [6, 6, 3, 2, 6, 4, 6, 5, 1], // 8
  139: [7, 7, 5, 8, 2, 5, 4, 8, 5], // 9
  140: [3, 6, 9, 1, 5, 5, 1, 4, 5], // 9
  141: [6, 3, 2, 3, 8, 1, 1, 7, 5], // 10
  142: [7, 3, 5, 5, 9, 8, 8, 6, 6], // 9
  143: [9, 3, 7, 2, 4, 2, 9, 8, 4], // 9
  144: [8, 3, 7, 3, 3, 5, 4, 4, 5], // 9
  145: [4, 7, 3, 7, 3, 1, 3, 1, 7], // 9
  146: [6, 6, 3, 2, 6, 4, 6, 5, 1], // 8
  147: [3, 6, 3, 6, 9, 7, 2, 3, 3], // 9
  148: [1, 8, 7, 6, 7, 6, 4, 4, 8], // 9
  149: [7, 9, 2, 3, 6, 1, 9, 8, 9], // 9
  150: [1, 1, 6, 7, 8, 6, 7, 4, 2], // 10
  151: [6, 1, 8, 6, 8, 4, 5, 7, 6], // 9
  152: [2, 3, 7, 4, 6, 4, 5, 5, 9], // 9
  153: [4, 8, 1, 2, 9, 5, 2, 4, 1], // 9
  154: [5, 2, 7, 7, 6, 1, 2, 3, 3], // 9
  155: [7, 7, 1, 2, 9, 8, 3, 4, 1], // 9
  156: [9, 1, 9, 7, 5, 6, 8, 8, 1], // 8
  157: [4, 2, 2, 5, 9, 9, 5, 6, 6], // 9
  158: [7, 1, 4, 6, 2, 6, 7, 5, 4], // 10
  159: [2, 2, 4, 2, 5, 4, 7, 9, 1], // 9
  160: [8, 7, 4, 8, 4, 7, 6, 2, 8], // 9
  161: [4, 3, 4, 9, 4, 6, 7, 7, 4], // 9
  162: [4, 1, 5, 9, 5, 1, 7, 1, 6], // 11
  163: [2, 2, 2, 9, 5, 6, 1, 9, 6], // 9
  164: [2, 9, 7, 9, 9, 8, 6, 4, 6], // 9
  165: [7, 9, 1, 4, 8, 8, 2, 3, 6], // 10
  166: [7, 3, 7, 5, 3, 9, 8, 8, 4], // 9
  167: [3, 9, 8, 3, 3, 4, 5, 7, 6], // 9
  168: [2, 2, 7, 3, 6, 8, 2, 3, 3], // 9
  169: [6, 7, 1, 1, 9, 2, 2, 7, 7], // 9
  170: [7, 2, 7, 9, 1, 7, 1, 7, 7], // 9
  171: [5, 6, 3, 9, 9, 7, 5, 2, 5], // 10
  172: [6, 5, 4, 4, 2, 7, 6, 2, 6], // 9
  173: [1, 4, 9, 3, 2, 2, 8, 1, 6], // 9
  174: [1, 9, 3, 9, 5, 5, 5, 9, 8], // 10
  175: [6, 3, 4, 4, 3, 2, 7, 8, 2], // 9
  176: [9, 8, 1, 6, 6, 2, 6, 1, 9], // 9
  177: [3, 4, 6, 6, 2, 4, 6, 2, 3], // 7
  178: [6, 4, 6, 8, 6, 6, 5, 5, 2], // 9
  179: [3, 7, 6, 6, 5, 9, 7, 3, 2], // 10
  180: [1, 5, 3, 3, 2, 2, 4, 6, 4], // 8
  181: [2, 8, 1, 7, 1, 7, 2, 5, 9], // 9
  182: [7, 3, 9, 5, 5, 3, 9, 8, 8], // 11
  183: [8, 7, 8, 4, 9, 7, 3, 6, 5], // 9
  184: [7, 5, 8, 7, 5, 7, 7, 1, 7], // 8
  185: [7, 6, 9, 7, 3, 7, 3, 3, 6], // 10
  186: [5, 8, 9, 1, 3, 4, 3, 7, 8], // 9
  187: [5, 4, 3, 6, 6, 3, 6, 1, 2], // 9
  188: [6, 6, 9, 5, 4, 1, 9, 7, 7], // 9
  189: [3, 7, 5, 9, 8, 6, 2, 9, 2], // 10
  190: [3, 8, 2, 8, 4, 2, 4, 4, 1], // 9
  191: [8, 1, 4, 4, 8, 8, 9, 6, 9], // 9
  192: [2, 5, 3, 1, 1, 9, 8, 9, 1], // 9
  193: [1, 7, 8, 2, 1, 4, 8, 2, 6], // 9
  194: [5, 3, 2, 4, 3, 8, 7, 4, 3], // 9
  195: [8, 8, 4, 2, 7, 7, 7, 5, 3], // 9
  196: [8, 1, 6, 3, 5, 3, 4, 6, 3], // 9
  197: [9, 9, 9, 6, 6, 1, 3, 8, 3], // 9
  198: [9, 3, 4, 7, 3, 5, 5, 4, 5], // 9
  199: [8, 2, 7, 3, 7, 8, 1, 9, 6], // 9
  200: [2, 1, 9, 5, 9, 4, 4, 9, 5], // 10
  201: [2, 5, 6, 3, 9, 5, 5, 2, 5], // 8
  202: [3, 9, 9, 4, 7, 7, 3, 5, 7], // 9
  203: [4, 3, 3, 4, 8, 5, 3, 8, 7], // 9
  204: [1, 6, 8, 6, 5, 1, 9, 8, 7], // 8
  205: [2, 1, 7, 2, 2, 7, 9, 2, 4], // 9
  206: [7, 6, 1, 2, 9, 6, 7, 2, 5], // 9
  207: [7, 4, 1, 3, 3, 6, 6, 5, 4], // 9
  208: [5, 9, 6, 1, 5, 3, 5, 3, 8], // 10
  209: [8, 3, 5, 5, 6, 6, 5, 9, 7], // 9
  210: [8, 8, 9, 4, 5, 1, 5, 7, 7], // 9
  211: [1, 3, 3, 5, 4, 4, 2, 2, 9], // 8
  212: [2, 9, 6, 9, 2, 1, 2, 8, 6], // 9
  213: [1, 4, 9, 7, 3, 6, 9, 3, 6], // 9
  214: [7, 1, 6, 6, 5, 2, 2, 1, 6], // 10
  215: [5, 5, 5, 3, 9, 9, 7, 3, 5], // 9
  216: [3, 1, 2, 8, 5, 4, 5, 1, 1], // 9
  217: [2, 3, 7, 7, 9, 7, 3, 9, 1], // 8
  218: [8, 9, 5, 5, 8, 1, 9, 5, 1], // 9
  219: [8, 5, 5, 3, 9, 8, 2, 7, 7], // 9
  220: [6, 1, 2, 3, 2, 3, 2, 4, 1], // 9
  221: [8, 7, 3, 4, 5, 2, 9, 4, 6], // 10
  222: [5, 6, 5, 1, 7, 2, 8, 9, 8], // 9
  223: [3, 8, 6, 9, 7, 3, 7, 4, 7], // 9
  224: [1, 4, 3, 9, 6, 2, 2, 8, 4], // 9
  225: [7, 8, 8, 9, 9, 9, 2, 8, 6], // 8
  226: [2, 6, 1, 7, 3, 1, 9, 3, 1], // 10
  227: [6, 2, 8, 8, 4, 8, 2, 4, 6], // 9
  228: [3, 8, 5, 1, 2, 3, 3, 7, 4], // 9
  229: [9, 7, 3, 8, 9, 3, 6, 9, 3], // 9
  230: [2, 2, 3, 7, 3, 6, 6, 7, 6], // 9
  231: [6, 5, 3, 8, 1, 8, 8, 6, 9], // 10
  232: [8, 1, 6, 4, 2, 2, 6, 1, 3], // 9
  233: [1, 2, 9, 7, 9, 2, 3, 2, 1], // 8
  234: [2, 9, 2, 6, 1, 3, 8, 7, 1], // 9
  235: [8, 8, 8, 7, 3, 6, 6, 6, 8], // 8
  236: [3, 9, 2, 4, 6, 4, 2, 9, 9], // 9
  237: [7, 5, 8, 4, 9, 1, 7, 9, 7], // 10
  238: [1, 6, 7, 8, 8, 6, 8, 9, 4], // 9
  239: [1, 1, 4, 4, 5, 6, 7, 6, 2], // 8
  240: [2, 5, 1, 7, 7, 5, 7, 5, 6], // 9
  241: [5, 2, 9, 2, 2, 2, 7, 9, 7], // 9
  242: [8, 1, 8, 4, 8, 8, 9, 4, 7], // 9
  243: [4, 6, 7, 4, 1, 9, 3, 3, 5], // 11
  244: [3, 2, 7, 2, 4, 2, 2, 7, 7], // 9
  245: [1, 7, 7, 7, 9, 4, 8, 6, 8], // 9
  246: [8, 7, 7, 6, 9, 7, 5, 5, 3], // 8
  247: [1, 8, 1, 6, 3, 2, 8, 7, 6], // 9
  248: [9, 9, 3, 6, 1, 2, 8, 7, 3], // 9
  249: [7, 8, 8, 9, 2, 3, 1, 1, 6], // 9
  250: [8, 4, 8, 6, 6, 9, 2, 3, 8], // 10
  251: [5, 5, 9, 9, 3, 4, 7, 4, 8], // 9
  252: [4, 8, 7, 6, 7, 6, 1, 8, 4], // 9
  253: [7, 8, 3, 2, 8, 9, 3, 5, 9], // 9
  254: [9, 5, 7, 1, 1, 1, 7, 9, 2], // 8
  255: [1, 1, 7, 3, 1, 1, 6, 7, 9], // 9
  256: [7, 9, 7, 3, 2, 8, 2, 9, 7], // 9
  257: [9, 7, 6, 9, 7, 7, 3, 7, 2], // 9
  258: [7, 2, 9, 8, 7, 9, 4, 8, 6], // 8
  259: [9, 5, 8, 6, 7, 7, 2, 8, 2], // 9
  260: [6, 7, 9, 9, 8, 6, 4, 7, 1], // 9
  261: [4, 6, 5, 5, 1, 6, 4, 6, 8], // 10
  262: [8, 8, 7, 2, 4, 7, 2, 3, 1], // 9
  263: [8, 8, 2, 1, 2, 5, 1, 7, 8], // 9
  264: [2, 7, 1, 9, 2, 9, 6, 2, 1], // 9
  265: [3, 6, 3, 8, 2, 6, 3, 1, 1], // 9
  266: [1, 2, 6, 7, 1, 7, 7, 6, 8], // 9
  267: [7, 5, 7, 4, 2, 7, 2, 6, 5], // 9
  268: [8, 2, 9, 8, 9, 7, 5, 3, 6], // 9
  269: [7, 8, 6, 5, 8, 7, 7, 3, 3], // 9
  270: [8, 9, 8, 8, 8, 2, 5, 8, 1], // 9
  271: [5, 2, 8, 1, 5, 3, 6, 2, 7], // 11
  272: [4, 3, 7, 2, 3, 6, 1, 2, 5], // 8
  273: [7, 9, 9, 1, 7, 2, 8, 5, 6], // 9
  274: [5, 9, 6, 9, 8, 7, 6, 9, 4], // 9
  275: [9, 5, 2, 8, 3, 4, 2, 2, 4], // 8
  276: [9, 5, 5, 5, 3, 1, 3, 2, 9], // 8
  277: [9, 5, 1, 2, 9, 9, 1, 8, 4], // 9
  278: [5, 4, 3, 4, 2, 6, 8, 7, 6], // 8
  279: [7, 3, 9, 5, 4, 1, 8, 4, 7], // 9
  280: [5, 6, 7, 4, 1, 6, 4, 5, 4], // 8
  281: [8, 7, 7, 8, 6, 7, 6, 4, 1], // 9
  282: [1, 3, 5, 2, 9, 8, 4, 4, 9], // 10
  283: [6, 8, 3, 4, 3, 5, 6, 9, 7], // 9
  284: [4, 6, 9, 8, 9, 1, 1, 9, 4], // 9
  285: [9, 4, 2, 4, 1, 5, 1, 2, 2], // 7
  286: [6, 3, 5, 8, 4, 5, 4, 4, 9], // 8
  287: [9, 5, 2, 6, 9, 9, 6, 7, 7], // 9
  288: [3, 3, 3, 5, 5, 2, 9, 6, 9], // 9
  289: [4, 6, 6, 2, 4, 2, 4, 5, 9], // 9
  290: [9, 5, 6, 8, 1, 4, 4, 2, 3], // 11
  291: [6, 7, 1, 5, 9, 3, 2, 6, 6], // 10
  292: [6, 2, 1, 5, 9, 8, 9, 3, 2], // 10
  293: [7, 8, 4, 5, 6, 3, 9, 3, 9], // 10
  294: [1, 7, 2, 1, 7, 6, 3, 4, 2], // 9
  295: [9, 7, 5, 6, 3, 7, 8, 4, 5], // 9
  296: [7, 2, 1, 2, 9, 6, 3, 2, 1], // 9
  297: [3, 7, 7, 7, 4, 1, 7, 7, 8], // 10
  298: [9, 3, 5, 5, 1, 7, 1, 5, 6], // 10
  299: [8, 3, 6, 8, 6, 7, 6, 2, 5], // 9
  300: [7, 8, 8, 9, 6, 7, 7, 3, 8], // 9
  301: [7, 5, 8, 3, 4, 6, 9, 8, 7], // 9
  302: [1, 6, 2, 8, 7, 5, 1, 9, 9], // 9
  303: [8, 6, 8, 9, 2, 4, 2, 2, 4], // 8
  304: [2, 7, 6, 8, 8, 8, 5, 8, 8], // 9
  305: [9, 6, 9, 5, 8, 8, 3, 6, 6], // 7
  306: [4, 6, 9, 6, 8, 9, 2, 7, 9], // 9
  307: [3, 9, 6, 2, 7, 1, 2, 3, 9], // 9
  308: [4, 6, 9, 2, 8, 1, 9, 2, 7], // 9
  309: [4, 2, 6, 1, 6, 9, 8, 4, 5], // 11
  310: [9, 7, 8, 3, 7, 4, 7, 3, 3], // 11
  311: [9, 3, 6, 3, 6, 8, 7, 7, 2], // 9
  312: [1, 8, 9, 2, 4, 4, 6, 3, 2], // 9
  313: [6, 2, 2, 5, 4, 3, 5, 2, 7], // 9
  314: [9, 9, 2, 6, 7, 1, 6, 2, 3], // 9
  315: [6, 3, 9, 8, 4, 8, 3, 8, 8], // 9
  316: [5, 1, 1, 2, 5, 1, 1, 9, 5], // 9
  317: [8, 7, 8, 1, 5, 1, 4, 7, 7], // 9
  318: [2, 2, 7, 5, 6, 5, 8, 3, 4], // 9
  319: [8, 8, 9, 5, 5, 6, 7, 3, 9], // 9
  320: [3, 4, 3, 6, 4, 2, 9, 8, 3], // 10
  321: [8, 5, 6, 2, 9, 2, 7, 2, 1], // 9
  322: [7, 1, 6, 6, 6, 1, 4, 5, 6], // 9
  323: [1, 2, 4, 6, 6, 1, 5, 4, 4], // 9
  324: [3, 4, 7, 9, 4, 4, 8, 5, 4], // 9
  325: [8, 3, 7, 2, 8, 4, 3, 4, 6], // 9
  326: [6, 8, 8, 5, 1, 8, 4, 1, 7], // 9
  327: [9, 5, 8, 4, 8, 8, 4, 1, 1], // 9
  328: [9, 9, 8, 6, 8, 7, 5, 3, 2], // 8
  329: [4, 7, 7, 9, 9, 5, 6, 2, 2], // 10
  330: [5, 9, 1, 5, 6, 3, 8, 9, 5], // 11
  331: [7, 1, 4, 8, 5, 4, 1, 6, 9], // 11
  332: [8, 8, 7, 3, 9, 7, 8, 7, 3], // 10
  333: [7, 8, 4, 2, 1, 2, 1, 1, 1], // 9
  334: [4, 1, 3, 6, 2, 7, 6, 2, 8], // 11
  335: [6, 8, 2, 5, 4, 5, 8, 9, 1], // 10
  336: [1, 3, 4, 1, 1, 2, 4, 1, 7], // 9
  337: [5, 1, 2, 8, 5, 1, 7, 6, 4], // 11
  338: [7, 7, 9, 6, 6, 6, 2, 5, 3], // 9
  339: [9, 5, 8, 7, 5, 6, 9, 4, 7], // 8
  340: [3, 4, 9, 5, 4, 4, 5, 6, 5], // 9
  341: [9, 7, 7, 6, 6, 7, 5, 6, 1], // 8
  342: [1, 2, 7, 7, 8, 7, 6, 8, 2], // 9
  343: [5, 6, 8, 4, 8, 9, 8, 5, 7], // 9
  344: [9, 6, 5, 4, 2, 2, 5, 8, 1], // 11
  345: [6, 2, 5, 6, 4, 9, 7, 3, 6], // 9
  346: [1, 8, 5, 2, 9, 3, 7, 8, 5], // 10
  347: [1, 4, 7, 3, 8, 7, 4, 1, 7], // 10
  348: [4, 9, 8, 8, 3, 8, 5, 7, 8], // 9
  349: [2, 3, 2, 7, 2, 5, 4, 5, 6], // 9
  350: [9, 1, 4, 4, 7, 9, 2, 4, 8], // 9
  351: [6, 9, 6, 8, 9, 8, 4, 9, 1], // 9
  352: [5, 5, 2, 4, 2, 5, 4, 4, 8], // 9
  353: [5, 7, 4, 1, 6, 1, 2, 6, 4], // 9
  354: [9, 1, 4, 3, 1, 5, 4, 3, 6], // 9
  355: [1, 4, 1, 1, 2, 7, 2, 7, 2], // 9
  356: [9, 5, 6, 6, 6, 6, 8, 3, 8], // 9
  357: [7, 5, 8, 3, 1, 2, 8, 3, 8], // 9
  358: [1, 3, 4, 7, 7, 6, 6, 6, 5], // 8
  359: [1, 6, 2, 3, 6, 2, 8, 4, 4], // 9
  360: [5, 8, 9, 1, 9, 9, 1, 2, 1], // 9
  361: [5, 8, 9, 1, 2, 3, 1, 4, 3], // 9
  362: [7, 2, 1, 3, 5, 5, 2, 1, 1], // 8
  363: [8, 4, 3, 8, 9, 2, 6, 8, 3], // 11
  364: [5, 8, 7, 2, 6, 8, 4, 2, 3], // 11
  365: [7, 3, 6, 4, 4, 9, 1, 3, 8], // 11
  366: [6, 4, 2, 8, 6, 5, 9, 7, 7], // 9
  367: [1, 2, 5, 3, 8, 8, 5, 7, 6], // 11
  368: [7, 1, 4, 3, 7, 3, 3, 9, 8], // 11
  369: [3, 5, 3, 5, 2, 8, 2, 3, 8], // 9
  370: [6, 1, 6, 3, 4, 2, 6, 4, 7], // 9
  371: [4, 1, 9, 5, 3, 2, 7, 9, 2], // 9
  372: [4, 9, 9, 6, 6, 8, 2, 1, 6], // 10
  373: [1, 5, 4, 8, 8, 8, 5, 7, 8], // 9
  374: [9, 4, 4, 4, 8, 1, 4, 4, 7], // 11
  375: [3, 9, 3, 9, 9, 2, 4, 1, 2], // 7
  376: [6, 7, 4, 2, 3, 4, 3, 9, 1], // 9
  377: [3, 2, 3, 4, 1, 8, 4, 7, 4], // 10
  378: [4, 9, 3, 2, 1, 5, 3, 7, 8], // 10
  379: [2, 4, 2, 2, 7, 3, 9, 1, 3], // 10
  380: [9, 3, 3, 9, 2, 3, 3, 8, 2], // 8
  381: [1, 4, 7, 4, 9, 6, 9, 7, 4], // 9
  382: [2, 9, 1, 6, 9, 3, 7, 8, 3], // 10
  383: [6, 1, 5, 4, 3, 3, 2, 3, 6], // 9
  384: [1, 9, 2, 2, 8, 6, 5, 8, 4], // 11
  385: [8, 1, 5, 7, 9, 1, 5, 3, 9], // 9
  386: [9, 5, 8, 2, 7, 7, 9, 8, 2], // 9
  387: [3, 9, 3, 9, 6, 9, 1, 5, 3], // 10
  388: [6, 3, 4, 4, 8, 2, 1, 1, 4], // 9
  389: [7, 3, 7, 8, 9, 5, 4, 1, 4], // 11
  390: [1, 6, 5, 5, 9, 7, 5, 8, 8], // 9
  391: [8, 3, 6, 7, 8, 3, 8, 8, 6], // 9
  392: [9, 2, 6, 8, 7, 6, 4, 3, 6], // 9
  393: [9, 2, 4, 8, 1, 8, 5, 5, 6], // 10
  394: [6, 8, 8, 8, 6, 3, 4, 6, 5], // 9
  395: [4, 3, 5, 5, 1, 7, 8, 4, 5], // 9
  396: [4, 8, 4, 3, 9, 7, 7, 5, 4], // 9
  397: [8, 3, 3, 6, 4, 3, 7, 7, 7], // 8
  398: [7, 1, 5, 1, 7, 2, 9, 4, 3], // 11
  399: [5, 4, 3, 6, 9, 4, 8, 1, 2], // 9
  400: [3, 8, 4, 7, 9, 1, 8, 3, 5], // 11
  401: [4, 1, 1, 2, 5, 3, 4, 5, 8], // 10
  402: [3, 8, 4, 5, 3, 8, 1, 3, 4], // 9
  403: [3, 4, 4, 2, 7, 7, 4, 6, 2], // 9
  404: [1, 1, 8, 4, 1, 9, 4, 3, 5], // 9
  405: [4, 3, 7, 9, 3, 5, 6, 6, 8], // 9
  406: [9, 4, 6, 8, 1, 8, 3, 1, 5], // 10
  407: [3, 5, 2, 5, 2, 8, 4, 3, 7], // 10
  408: [6, 8, 4, 7, 7, 6, 9, 6, 1], // 9
  409: [6, 3, 3, 9, 2, 3, 9, 5, 5], // 10
  410: [9, 7, 9, 9, 2, 6, 7, 1, 1], // 8
  411: [9, 6, 3, 6, 8, 4, 8, 5, 2], // 8
  412: [6, 7, 8, 8, 6, 5, 3, 1, 7], // 9
  413: [2, 1, 2, 1, 2, 5, 6, 9, 2], // 9
  414: [6, 8, 8, 5, 7, 1, 6, 4, 6], // 9
  415: [1, 6, 6, 2, 8, 7, 9, 4, 5], // 9
  416: [7, 1, 6, 4, 5, 7, 9, 9, 3], // 10
  417: [3, 5, 9, 3, 7, 6, 1, 4, 4], // 10
  418: [8, 4, 9, 9, 6, 2, 7, 1, 8], // 9
  419: [9, 2, 4, 7, 2, 3, 4, 2, 9], // 9
  420: [4, 6, 8, 8, 7, 4, 5, 9, 9], // 9
  421: [6, 6, 3, 4, 1, 9, 9, 3, 4], // 9
  422: [7, 5, 8, 5, 7, 4, 8, 7, 3], // 9
  423: [2, 8, 8, 6, 1, 2, 5, 6, 7], // 9
  424: [6, 5, 3, 1, 2, 3, 8, 6, 5], // 10
  425: [4, 1, 5, 4, 2, 6, 7, 9, 7], // 10
  426: [2, 3, 3, 2, 3, 5, 1, 2, 6], // 7
  427: [1, 7, 5, 7, 3, 9, 7, 4, 8], // 10
  428: [7, 5, 3, 6, 9, 8, 8, 7, 7], // 9
  429: [6, 3, 9, 7, 4, 4, 9, 2, 4], // 10
  430: [7, 7, 4, 5, 9, 4, 8, 8, 8], // 8
  431: [5, 4, 5, 9, 7, 1, 9, 2, 3], // 10
  432: [4, 7, 6, 9, 5, 4, 4, 7, 2], // 10
  433: [1, 1, 2, 7, 3, 3, 4, 5, 4], // 9
  434: [8, 3, 8, 1, 4, 7, 1, 2, 5], // 10
  435: [6, 3, 8, 8, 5, 4, 8, 4, 2], // 9
  436: [1, 1, 3, 5, 4, 3, 2, 4, 1], // 8
  437: [9, 3, 3, 6, 3, 9, 6, 6, 9], // 8
  438: [9, 3, 6, 9, 4, 8, 1, 6, 8], // 10
  439: [2, 3, 5, 9, 6, 3, 5, 6, 6], // 9
  440: [1, 1, 6, 7, 4, 5, 1, 9, 8], // 9
  441: [2, 5, 5, 7, 9, 7, 5, 6, 8], // 9
  442: [7, 9, 4, 6, 3, 8, 8, 5, 4], // 10
  443: [5, 9, 3, 3, 9, 3, 8, 9, 2], // 10
  444: [3, 9, 2, 1, 5, 4, 9, 2, 7], // 10
  445: [6, 8, 6, 8, 3, 6, 6, 7, 1], // 9
  446: [5, 5, 5, 9, 5, 9, 3, 7, 3], // 8
  447: [9, 3, 1, 9, 5, 3, 1, 5, 9], // 8
  448: [1, 2, 3, 8, 6, 5, 8, 6, 6], // 9
  449: [4, 2, 7, 7, 3, 2, 3, 1, 1], // 9
  450: [3, 2, 4, 5, 2, 7, 2, 3, 5], // 9
  451: [9, 2, 8, 4, 8, 9, 1, 4, 3], // 9
  452: [3, 4, 3, 3, 8, 4, 8, 9, 9], // 9
  453: [2, 2, 2, 5, 7, 5, 4, 1, 5], // 8
  454: [8, 1, 6, 9, 4, 5, 3, 5, 7], // 11
  455: [1, 9, 8, 4, 5, 7, 4, 1, 6], // 11
  456: [1, 9, 2, 3, 2, 2, 4, 6, 1], // 9
  457: [8, 4, 7, 1, 5, 6, 1, 6, 4], // 9
  458: [9, 6, 1, 4, 4, 4, 6, 9, 2], // 11
  459: [2, 2, 5, 9, 9, 1, 8, 2, 4], // 9
  460: [7, 4, 5, 9, 5, 4, 6, 8, 9], // 9
  461: [5, 8, 4, 8, 1, 6, 1, 7, 5], // 9
  462: [4, 8, 7, 1, 2, 2, 8, 5, 8], // 11
  463: [8, 6, 5, 5, 8, 5, 1, 6, 1], // 9
  464: [9, 7, 6, 5, 1, 4, 2, 1, 4], // 10
  465: [6, 5, 8, 3, 1, 6, 6, 5, 2], // 9
  466: [3, 3, 1, 4, 8, 9, 4, 3, 1], // 8
  467: [6, 3, 4, 5, 4, 4, 8, 6, 2], // 9
  468: [6, 6, 3, 6, 7, 9, 9, 5, 6], // 9
  469: [1, 2, 8, 9, 4, 9, 7, 8, 3], // 9
  470: [4, 3, 6, 8, 3, 8, 2, 8, 9], // 9
  471: [9, 8, 9, 8, 9, 6, 7, 9, 4], // 9
  472: [2, 9, 2, 3, 9, 3, 5, 6, 6], // 10
  473: [5, 6, 1, 9, 5, 2, 5, 7, 8], // 10
  474: [3, 1, 9, 9, 1, 3, 7, 6, 3], // 10
  475: [8, 2, 9, 5, 2, 8, 8, 3, 6], // 10
  476: [1, 7, 8, 7, 6, 9, 9, 2, 2], // 9
  477: [2, 5, 6, 7, 6, 6, 2, 7, 4], // 9
  478: [1, 2, 1, 5, 2, 8, 3, 1, 1], // 9
  479: [4, 5, 4, 8, 7, 4, 8, 6, 5], // 7
  480: [5, 4, 9, 9, 2, 8, 5, 1, 2], // 10
  481: [5, 2, 9, 2, 8, 7, 8, 4, 9], // 10
  482: [2, 7, 8, 7, 3, 1, 8, 9, 6], // 9
  483: [3, 9, 4, 5, 6, 7, 5, 3, 9], // 9
  484: [4, 1, 9, 7, 9, 9, 2, 1, 3], // 9
  485: [1, 7, 5, 9, 6, 7, 7, 3, 9], // 9
  486: [9, 1, 4, 6, 4, 9, 8, 2, 5], // 11
  487: [7, 2, 2, 5, 4, 4, 7, 3, 5], // 8
  488: [6, 1, 4, 5, 5, 9, 1, 6, 8], // 9
  489: [5, 5, 6, 6, 5, 5, 4, 6, 9], // 8
  490: [7, 9, 8, 8, 2, 5, 6, 7, 2], // 9
  491: [6, 6, 2, 7, 7, 4, 6, 3, 4], // 8
  492: [4, 7, 7, 7, 5, 9, 5, 5, 2], // 9
  493: [1, 5, 1, 6, 8, 3, 4, 7, 1], // 11
  494: [3, 6, 5, 5, 6, 1, 3, 9, 4], // 9
  495: [6, 9, 5, 8, 4, 8, 3, 7, 1], // 9
  496: [1, 5, 4, 4, 9, 5, 8, 6, 6], // 9
  497: [1, 3, 9, 3, 8, 8, 8, 5, 9], // 9
  498: [8, 2, 7, 5, 7, 9, 8, 6, 2], // 9
  499: [6, 2, 8, 7, 2, 8, 1, 2, 9], // 9
  500: [8, 1, 2, 7, 1, 4, 9, 1, 6], // 10
  501: [9, 8, 6, 8, 3, 4, 5, 8, 6], // 10
  502: [8, 4, 8, 4, 3, 3, 7, 5, 9], // 10
  503: [2, 9, 7, 4, 9, 4, 8, 9, 5], // 11
  504: [7, 8, 7, 9, 9, 4, 9, 2, 5], // 9
  505: [9, 3, 5, 6, 1, 8, 5, 8, 6], // 11
  506: [9, 6, 7, 3, 7, 9, 1, 9, 3], // 9
  507: [4, 1, 1, 2, 7, 2, 7, 6, 6], // 9
  508: [2, 5, 7, 5, 1, 2, 7, 2, 5], // 9
  509: [5, 6, 3, 7, 9, 7, 6, 1, 1], // 10
  510: [1, 9, 3, 2, 6, 7, 4, 4, 6], // 10
  511: [8, 8, 5, 5, 5, 8, 4, 3, 2], // 9
  512: [4, 1, 6, 2, 6, 9, 9, 1, 1], // 10
  513: [2, 6, 9, 7, 3, 8, 3, 6, 7], // 10
  514: [5, 9, 2, 5, 4, 2, 7, 8, 6], // 10
  515: [3, 2, 8, 8, 4, 7, 8, 9, 2], // 9
  516: [3, 2, 8, 1, 9, 5, 1, 1, 9], // 9
  517: [3, 1, 9, 5, 9, 5, 3, 2, 5], // 9
  518: [9, 2, 3, 4, 9, 6, 8, 7, 9], // 10
  519: [7, 8, 2, 6, 3, 9, 2, 1, 1], // 9
  520: [9, 4, 3, 5, 3, 3, 9, 1, 2], // 9
  521: [7, 9, 2, 9, 2, 4, 4, 6, 8], // 9
  522: [6, 3, 4, 1, 5, 6, 6, 7, 4], // 9
  523: [6, 8, 6, 4, 9, 3, 5, 4, 6], // 9
  524: [4, 4, 1, 3, 7, 6, 2, 8, 1], // 11
  525: [1, 2, 7, 7, 1, 5, 6, 3, 4], // 9
  526: [2, 8, 5, 5, 5, 9, 2, 2, 4], // 10
  527: [2, 6, 4, 6, 9, 2, 1, 2, 1], // 9
  528: [2, 8, 1, 1, 1, 1, 7, 6, 3], // 9
  529: [3, 8, 4, 3, 1, 4, 8, 4, 4], // 9
  530: [1, 6, 3, 3, 6, 1, 1, 1, 2], // 9
  531: [8, 1, 8, 6, 5, 7, 1, 2, 7], // 9
  532: [4, 7, 7, 3, 4, 4, 7, 8, 4], // 9
  533: [9, 7, 9, 4, 5, 7, 3, 5, 8], // 9
  534: [9, 5, 3, 1, 4, 8, 9, 5, 4], // 9
  535: [6, 5, 5, 7, 5, 9, 4, 9, 4], // 9
  536: [2, 9, 1, 3, 8, 7, 1, 7, 4], // 9
  537: [5, 2, 5, 6, 5, 3, 4, 7, 8], // 9
  538: [7, 2, 4, 2, 6, 8, 1, 2, 7], // 9
  539: [3, 5, 6, 6, 5, 6, 8, 3, 9], // 8
  540: [8, 5, 5, 4, 6, 2, 4, 8, 9], // 10
  541: [8, 4, 7, 6, 6, 5, 3, 5, 1], // 9
  542: [5, 6, 1, 2, 8, 9, 8, 1, 8], // 9
  543: [2, 1, 7, 1, 5, 8, 9, 8, 1], // 9
  544: [3, 7, 2, 9, 3, 9, 9, 8, 1], // 9
  545: [8, 5, 7, 1, 1, 5, 8, 4, 9], // 10
  546: [4, 5, 9, 7, 1, 5, 7, 7, 3], // 10
  547: [6, 2, 1, 1, 8, 7, 1, 9, 1], // 9
  548: [7, 1, 9, 4, 3, 2, 7, 9, 3], // 9
  549: [6, 4, 7, 7, 1, 8, 7, 2, 9], // 9
  550: [1, 8, 2, 8, 6, 3, 7, 5, 8], // 10
  551: [9, 5, 8, 1, 1, 9, 8, 3, 4], // 9
  552: [8, 5, 4, 8, 6, 8, 6, 3, 3], // 9
  553: [8, 9, 3, 3, 9, 5, 9, 4, 1], // 10
  554: [1, 2, 7, 3, 1, 3, 5, 3, 5], // 9
  555: [2, 9, 6, 1, 3, 4, 9, 6, 8], // 11
  556: [1, 7, 7, 8, 9, 4, 4, 7, 4], // 9
  557: [7, 6, 4, 1, 3, 7, 7, 6, 1], // 9
  558: [2, 8, 1, 1, 6, 3, 2, 3, 7], // 9
  559: [5, 7, 9, 5, 2, 7, 9, 5, 5], // 9
  560: [3, 7, 4, 5, 5, 1, 9, 3, 8], // 11
  561: [3, 1, 8, 4, 5, 8, 7, 9, 9], // 10
  562: [7, 7, 5, 7, 2, 9, 6, 9, 8], // 9
  563: [4, 5, 5, 6, 8, 7, 9, 5, 2], // 10
  564: [7, 7, 3, 7, 9, 6, 2, 7, 3], // 9
  565: [8, 6, 4, 9, 9, 8, 2, 9, 8], // 9
  566: [4, 6, 7, 9, 9, 1, 4, 8, 9], // 9
  567: [2, 3, 3, 2, 6, 8, 8, 3, 1], // 9
  568: [1, 5, 6, 4, 3, 2, 8, 3, 4], // 9
  569: [3, 8, 1, 8, 8, 5, 8, 9, 1], // 9
  570: [9, 1, 3, 1, 7, 7, 4, 1, 9], // 8
  571: [1, 6, 3, 1, 9, 7, 3, 2, 4], // 10
  572: [9, 8, 2, 8, 7, 2, 7, 3, 2], // 9
  573: [5, 3, 6, 1, 9, 8, 6, 5, 2], // 11
  574: [3, 8, 9, 8, 4, 9, 7, 2, 7], // 8
  575: [7, 3, 8, 9, 1, 9, 6, 3, 2], // 9
  576: [9, 4, 5, 4, 9, 1, 1, 4, 5], // 9
  577: [4, 5, 7, 4, 5, 4, 4, 9, 3], // 9
  578: [5, 3, 2, 3, 6, 4, 5, 7, 1], // 9
  579: [5, 9, 8, 8, 9, 5, 9, 5, 5], // 9
  580: [4, 3, 6, 3, 9, 4, 1, 4, 2], // 8
  581: [1, 4, 8, 8, 5, 1, 1, 7, 7], // 9
  582: [2, 8, 8, 1, 9, 7, 6, 4, 6], // 9
  583: [5, 3, 5, 4, 1, 3, 4, 2, 6], // 8
  584: [3, 8, 2, 7, 1, 7, 7, 7, 6], // 9
  585: [2, 4, 1, 2, 1, 9, 7, 7, 3], // 9
  586: [5, 5, 2, 2, 2, 6, 8, 6, 6], // 9
  587: [8, 4, 7, 9, 4, 4, 4, 8, 6], // 9
  588: [4, 8, 8, 5, 5, 7, 3, 9, 5], // 9
  589: [9, 7, 6, 9, 1, 3, 9, 4, 9], // 9
  590: [3, 6, 7, 3, 5, 1, 1, 2, 2], // 9
  591: [1, 1, 7, 8, 9, 8, 5, 6, 9], // 7
  592: [1, 1, 2, 4, 2, 7, 9, 3, 4], // 9
  593: [4, 5, 5, 1, 6, 4, 4, 7, 9], // 9
  594: [2, 1, 1, 7, 8, 1, 2, 2, 9], // 7
  595: [1, 1, 3, 7, 4, 2, 5, 6, 4], // 10
  596: [7, 6, 7, 3, 1, 6, 2, 5, 8], // 10
  597: [3, 2, 6, 3, 9, 6, 5, 9, 5], // 9
  598: [4, 7, 7, 8, 8, 2, 1, 8, 3], // 9
  599: [6, 9, 4, 2, 7, 7, 6, 9, 7], // 9
  600: [4, 8, 2, 3, 2, 7, 1, 6, 9], // 9
  601: [7, 8, 1, 7, 1, 8, 4, 1, 2], // 8
  602: [4, 2, 1, 6, 7, 7, 2, 3, 1], // 9
  603: [8, 3, 3, 5, 7, 5, 1, 1, 3], // 10
  604: [4, 9, 3, 4, 2, 5, 5, 5, 8], // 9
  605: [2, 3, 3, 1, 1, 6, 6, 7, 1], // 8
  606: [5, 2, 6, 4, 2, 1, 6, 1, 6], // 9
  607: [8, 1, 4, 9, 9, 5, 8, 2, 5], // 9
  608: [1, 1, 4, 7, 9, 6, 7, 9, 4], // 9
  609: [5, 7, 5, 7, 5, 1, 7, 4, 4], // 9
  610: [9, 3, 7, 1, 1, 7, 8, 2, 4], // 10
  611: [5, 4, 8, 9, 3, 7, 4, 4, 4], // 9
  612: [2, 8, 9, 1, 9, 6, 6, 3, 4], // 10
  613: [9, 6, 7, 8, 2, 7, 9, 7, 2], // 9
  614: [9, 3, 1, 2, 3, 7, 6, 9, 2], // 9
  615: [3, 4, 2, 2, 8, 1, 9, 5, 8], // 9
  616: [4, 3, 2, 1, 9, 9, 1, 7, 9], // 9
  617: [8, 1, 7, 7, 2, 9, 8, 7, 2], // 8
  618: [9, 7, 6, 5, 7, 2, 9, 4, 5], // 10
  619: [8, 6, 5, 1, 9, 2, 5, 2, 1], // 9
  620: [9, 6, 1, 3, 3, 7, 1, 9, 9], // 9
  621: [7, 5, 3, 7, 5, 6, 1, 6, 5], // 9
  622: [7, 3, 8, 8, 7, 6, 1, 5, 9], // 9
  623: [6, 4, 3, 3, 4, 1, 9, 1, 2], // 9
  624: [7, 6, 1, 4, 9, 4, 4, 2, 2], // 10
  625: [3, 2, 2, 8, 3, 7, 6, 7, 1], // 11
  626: [1, 6, 7, 3, 6, 7, 6, 4, 5], // 9
  627: [1, 1, 9, 7, 2, 2, 2, 4, 2], // 9
  628: [3, 3, 6, 8, 7, 7, 7, 5, 5], // 9
  629: [4, 9, 8, 5, 5, 7, 9, 9, 1], // 9
  630: [3, 6, 6, 4, 3, 6, 3, 4, 1], // 8
  631: [1, 1, 9, 7, 9, 4, 1, 8, 2], // 9
  632: [4, 6, 2, 2, 5, 3, 2, 9, 3], // 9
  633: [3, 1, 5, 7, 2, 5, 6, 9, 4], // 9
  634: [9, 4, 7, 3, 4, 2, 1, 9, 3], // 9
  635: [6, 5, 9, 4, 3, 6, 2, 4, 3], // 9
  636: [5, 3, 1, 3, 9, 9, 9, 4, 8], // 9
  637: [5, 2, 1, 5, 5, 5, 6, 9, 4], // 9
  638: [9, 4, 8, 8, 8, 5, 8, 1, 6], // 9
  639: [4, 4, 4, 1, 1, 5, 5, 9, 6], // 9
  640: [5, 3, 1, 2, 3, 5, 2, 7, 2], // 9
  641: [9, 4, 6, 7, 4, 7, 4, 8, 5], // 9
  642: [7, 1, 9, 5, 3, 1, 2, 2, 9], // 9
  643: [9, 8, 7, 4, 5, 6, 3, 4, 2], // 9
  644: [1, 4, 2, 8, 1, 9, 6, 8, 6], // 9
  645: [9, 6, 9, 8, 1, 9, 4, 7, 4], // 8
  646: [7, 6, 7, 7, 5, 7, 9, 5, 4], // 8
  647: [3, 7, 6, 3, 1, 6, 1, 8, 7], // 10
  648: [4, 9, 8, 5, 1, 9, 6, 1, 5], // 8
  649: [3, 3, 8, 4, 3, 2, 5, 9, 2], // 9
  650: [5, 3, 5, 5, 2, 8, 6, 8, 3], // 9
  651: [2, 1, 9, 3, 5, 9, 2, 3, 8], // 9
  652: [3, 3, 4, 2, 8, 3, 1, 8, 4], // 8
  653: [8, 8, 3, 7, 9, 6, 3, 5, 5], // 9
  654: [1, 9, 2, 5, 8, 7, 1, 1, 5], // 10
  655: [4, 3, 2, 6, 3, 7, 6, 2, 6], // 9
  656: [6, 5, 5, 8, 5, 7, 6, 3, 6], // 8
  657: [6, 5, 5, 3, 4, 7, 9, 3, 3], // 10
  658: [2, 4, 5, 3, 4, 4, 1, 2, 5], // 7
  659: [5, 9, 7, 1, 1, 4, 1, 4, 4], // 10
  660: [4, 8, 6, 5, 5, 4, 7, 9, 3], // 10
  661: [4, 4, 2, 4, 1, 8, 1, 3, 3], // 8
  662: [4, 7, 3, 5, 5, 9, 9, 6, 9], // 10
  663: [8, 2, 9, 9, 2, 5, 1, 1, 2], // 9
  664: [1, 9, 3, 4, 3, 3, 6, 4, 9], // 9
  665: [7, 3, 6, 6, 1, 8, 3, 1, 4], // 11
  666: [7, 2, 7, 2, 1, 3, 3, 4, 7], // 9
  667: [2, 1, 8, 7, 3, 8, 5, 4, 4], // 9
  668: [1, 6, 5, 1, 6, 1, 9, 7, 6], // 9
  669: [5, 7, 1, 9, 9, 4, 1, 4, 8], // 9
  670: [9, 9, 9, 3, 3, 2, 3, 3, 7], // 7
  671: [2, 2, 1, 9, 1, 4, 9, 5, 9], // 9
  672: [6, 8, 1, 1, 2, 6, 6, 7, 8], // 9
  673: [3, 4, 3, 3, 1, 9, 9, 2, 8], // 8
  674: [1, 7, 9, 6, 9, 1, 6, 4, 5], // 9
  675: [6, 1, 1, 2, 7, 1, 1, 7, 1], // 8
  676: [8, 1, 9, 8, 5, 1, 3, 7, 6], // 10
  677: [9, 9, 9, 2, 4, 9, 6, 6, 9], // 9
  678: [1, 5, 4, 5, 9, 2, 6, 6, 4], // 10
  679: [5, 3, 9, 6, 4, 2, 3, 6, 1], // 9
  680: [5, 3, 5, 8, 7, 3, 3, 4, 4], // 9
  681: [5, 1, 2, 3, 6, 3, 6, 1, 9], // 9
  682: [4, 9, 3, 2, 4, 9, 6, 5, 6], // 9
  683: [9, 7, 9, 3, 9, 7, 9, 5, 5], // 9
  684: [6, 3, 7, 5, 2, 6, 5, 8, 6], // 8
  685: [1, 7, 4, 3, 1, 3, 8, 7, 5], // 10
  686: [2, 8, 4, 3, 8, 5, 4, 2, 6], // 9
  687: [4, 9, 6, 7, 5, 6, 5, 9, 3], // 10
  688: [6, 4, 2, 6, 2, 6, 4, 1, 8], // 10
  689: [2, 7, 7, 6, 4, 8, 9, 7, 7], // 9
  690: [7, 4, 1, 7, 1, 3, 6, 5, 5], // 10
  691: [6, 6, 3, 3, 3, 2, 4, 2, 4], // 8
  692: [9, 9, 8, 5, 2, 1, 1, 3, 7], // 9
  693: [2, 1, 1, 4, 8, 4, 9, 7, 9], // 9
  694: [9, 3, 9, 9, 6, 3, 7, 8, 3], // 9
  695: [4, 8, 5, 2, 1, 2, 9, 1, 1], // 9
  696: [8, 6, 2, 5, 8, 9, 1, 2, 7], // 9
  697: [5, 9, 6, 6, 7, 4, 3, 6, 8], // 9
  698: [3, 2, 2, 5, 8, 3, 4, 4, 2], // 9
  699: [6, 4, 6, 3, 4, 2, 4, 6, 1], // 9
  700: [1, 5, 9, 4, 4, 7, 9, 9, 3], // 11
  701: [4, 4, 4, 3, 3, 8, 1, 3, 6], // 9
  702: [1, 6, 1, 6, 1, 6, 8, 8, 5], // 9
  703: [1, 2, 1, 6, 9, 2, 9, 6, 6], // 9
  704: [9, 3, 6, 9, 2, 4, 3, 6, 9], // 9
  705: [2, 5, 7, 1, 3, 6, 9, 4, 2], // 10
  706: [9, 9, 9, 5, 5, 5, 7, 3, 8], // 9
  707: [9, 1, 3, 3, 8, 5, 4, 4, 8], // 10
  708: [2, 4, 1, 6, 5, 2, 2, 5, 6], // 9
  709: [3, 8, 4, 6, 3, 5, 2, 6, 2], // 9
  710: [4, 9, 7, 7, 6, 4, 4, 2, 2], // 9
  711: [2, 8, 5, 6, 2, 3, 3, 6, 7], // 10
  712: [5, 1, 8, 3, 8, 3, 1, 6, 7], // 11
  713: [4, 2, 2, 2, 1, 5, 8, 5, 4], // 9
  714: [1, 5, 6, 3, 8, 4, 5, 9, 4], // 10
  715: [5, 3, 4, 2, 4, 9, 1, 7, 1], // 9
  716: [1, 7, 9, 4, 3, 4, 9, 2, 3], // 9
  717: [1, 9, 7, 6, 9, 8, 5, 7, 8], // 7
  718: [6, 8, 8, 7, 1, 8, 2, 8, 3], // 8
  719: [1, 7, 1, 2, 1, 6, 3, 2, 1], // 9
  720: [4, 3, 2, 4, 6, 3, 4, 9, 1], // 9
  721: [4, 6, 9, 8, 9, 5, 1, 2, 7], // 9
  722: [5, 6, 6, 5, 4, 6, 3, 9, 1], // 9
  723: [6, 5, 5, 9, 4, 5, 8, 3, 9], // 9
  724: [6, 5, 6, 6, 9, 2, 1, 9, 7], // 9
  725: [1, 4, 9, 4, 7, 3, 5, 8, 7], // 9
  726: [3, 4, 9, 9, 3, 2, 5, 2, 8], // 9
  727: [8, 2, 4, 4, 3, 3, 5, 1, 3], // 9
  728: [2, 1, 5, 9, 6, 9, 9, 8, 5], // 9
  729: [7, 5, 3, 8, 5, 8, 4, 5, 6], // 9
  730: [4, 5, 4, 7, 2, 4, 2, 4, 7], // 7
  731: [2, 5, 4, 7, 7, 5, 7, 1, 4], // 10
  732: [9, 3, 6, 1, 9, 8, 7, 8, 3], // 9
  733: [2, 1, 7, 6, 8, 7, 6, 5, 6], // 9
  734: [7, 6, 3, 9, 1, 8, 2, 8, 1], // 8
  735: [8, 5, 5, 9, 9, 8, 8, 9, 8], // 8
  736: [9, 5, 4, 6, 9, 8, 4, 4, 5], // 8
  737: [5, 4, 4, 8, 6, 2, 6, 6, 1], // 10
  738: [1, 4, 7, 8, 3, 3, 2, 3, 5], // 11
  739: [8, 2, 1, 9, 3, 2, 5, 6, 6], // 9
  740: [1, 8, 4, 8, 6, 6, 2, 2, 5], // 10
  741: [4, 7, 9, 6, 5, 4, 9, 5, 8], // 9
  742: [8, 1, 6, 7, 3, 8, 2, 8, 2], // 10
  743: [9, 9, 6, 3, 9, 3, 2, 7, 9], // 10
  744: [9, 5, 4, 5, 3, 6, 9, 5, 8], // 9
  745: [7, 5, 7, 1, 7, 8, 6, 9, 7], // 8
  746: [2, 9, 3, 3, 2, 1, 6, 6, 4], // 9
  747: [6, 1, 8, 8, 6, 5, 1, 6, 7], // 9
  748: [9, 8, 9, 8, 3, 6, 7, 2, 2], // 9
  749: [9, 4, 3, 6, 6, 7, 3, 8, 8], // 9
  750: [8, 4, 2, 2, 1, 1, 5, 2, 2], // 9
  751: [4, 9, 5, 4, 4, 5, 5, 9, 9], // 7
  752: [6, 2, 5, 9, 7, 1, 2, 8, 8], // 9
  753: [6, 1, 3, 6, 3, 4, 1, 5, 4], // 9
  754: [2, 9, 3, 2, 5, 1, 2, 1, 5], // 9
  755: [6, 9, 4, 5, 3, 1, 4, 9, 7], // 10
  756: [3, 5, 8, 1, 7, 5, 6, 2, 8], // 9
  757: [7, 2, 3, 9, 2, 7, 1, 3, 8], // 7
  758: [2, 4, 9, 8, 4, 1, 9, 3, 2], // 8
  759: [8, 5, 8, 3, 8, 5, 8, 6, 3], // 9
  760: [1, 5, 2, 6, 3, 9, 4, 3, 9], // 9
  761: [8, 2, 2, 4, 4, 3, 9, 3, 7], // 9
  762: [2, 9, 8, 3, 4, 5, 8, 7, 8], // 9
  763: [7, 2, 3, 9, 7, 5, 3, 1, 8], // 10
  764: [2, 9, 3, 8, 2, 4, 1, 8, 5], // 9
  765: [7, 5, 6, 2, 2, 8, 5, 9, 7], // 10
  766: [5, 6, 7, 2, 6, 7, 2, 9, 7], // 9
  767: [9, 7, 5, 9, 4, 8, 3, 7, 8], // 8
  768: [6, 8, 5, 2, 4, 3, 4, 7, 6], // 9
  769: [3, 1, 6, 9, 9, 6, 2, 6, 3], // 9
  770: [7, 8, 4, 5, 9, 8, 1, 2, 1], // 10
  771: [7, 3, 7, 1, 2, 9, 8, 3, 2], // 9
  772: [9, 1, 7, 3, 9, 8, 9, 5, 6], // 9
  773: [7, 1, 6, 6, 2, 6, 7, 5, 8], // 9
  774: [9, 2, 1, 9, 2, 4, 7, 4, 4], // 9
  775: [8, 3, 9, 1, 5, 3, 9, 3, 4], // 9
  776: [2, 2, 4, 3, 8, 1, 5, 2, 6], // 9
  777: [8, 6, 4, 7, 2, 8, 6, 5, 5], // 9
  778: [9, 6, 1, 8, 9, 5, 7, 6, 6], // 9
  779: [1, 9, 1, 8, 7, 3, 8, 2, 3], // 8
  780: [3, 1, 9, 6, 5, 2, 7, 3, 9], // 10
  781: [2, 6, 6, 7, 7, 8, 4, 1, 4], // 10
  782: [1, 2, 6, 8, 5, 5, 2, 8, 8], // 9
  783: [9, 9, 4, 6, 4, 5, 2, 2, 1], // 9
  784: [5, 3, 6, 8, 5, 4, 2, 6, 6], // 9
  785: [9, 8, 1, 4, 9, 2, 7, 6, 8], // 9
  786: [5, 4, 1, 4, 3, 7, 7, 5, 6], // 9
  787: [3, 5, 7, 7, 2, 8, 2, 5, 6], // 9
  788: [2, 4, 3, 4, 4, 4, 6, 1, 5], // 8
  789: [5, 9, 2, 6, 9, 6, 8, 7, 8], // 9
  790: [8, 2, 2, 3, 1, 1, 2, 9, 5], // 8
  791: [1, 6, 7, 9, 4, 6, 5, 8, 8], // 9
  792: [3, 2, 6, 3, 2, 6, 8, 8, 4], // 9
  793: [2, 5, 9, 6, 2, 3, 7, 4, 4], // 9
  794: [7, 5, 5, 8, 8, 1, 7, 3, 4], // 10
  795: [4, 2, 9, 7, 5, 2, 4, 7, 2], // 11
  796: [1, 7, 7, 1, 9, 8, 2, 3, 4], // 9
  797: [9, 2, 8, 1, 1, 7, 1, 7, 3], // 9
  798: [4, 7, 2, 3, 5, 4, 9, 3, 5], // 9
  799: [5, 7, 7, 7, 3, 1, 6, 7, 5], // 10
  800: [7, 4, 7, 2, 5, 7, 6, 2, 5], // 9
  801: [9, 9, 7, 4, 3, 9, 9, 4, 3], // 9
  802: [4, 5, 2, 2, 9, 1, 9, 6, 1], // 9
  803: [1, 8, 9, 3, 2, 1, 2, 6, 7], // 9
  804: [8, 7, 5, 2, 6, 8, 1, 6, 8], // 9
  805: [1, 1, 7, 9, 8, 9, 5, 3, 2], // 9
  806: [3, 8, 7, 6, 8, 9, 5, 1, 7], // 9
  807: [8, 7, 5, 9, 3, 7, 6, 9, 6], // 10
  808: [7, 9, 2, 7, 5, 5, 5, 6, 5], // 10
  809: [1, 9, 6, 3, 4, 1, 6, 1, 8], // 10
  810: [2, 4, 2, 4, 7, 5, 4, 8, 3], // 9
  811: [8, 8, 5, 1, 4, 1, 7, 5, 3], // 11
  812: [2, 4, 6, 1, 3, 4, 8, 5, 9], // 11
  813: [5, 3, 9, 3, 6, 8, 5, 8, 1], // 9
  814: [6, 7, 8, 9, 2, 7, 3, 1, 8], // 9
  815: [4, 5, 7, 6, 6, 6, 9, 4, 7], // 8
  816: [1, 6, 6, 8, 7, 9, 8, 1, 5], // 8
  817: [6, 2, 6, 7, 4, 3, 2, 1, 5], // 9
  818: [4, 2, 8, 8, 4, 7, 6, 1, 8], // 11
  819: [2, 3, 9, 3, 4, 7, 6, 7, 7], // 9
  820: [3, 7, 5, 5, 1, 7, 1, 6, 1], // 10
  821: [6, 4, 3, 7, 1, 6, 5, 7, 6], // 9
  822: [4, 8, 4, 8, 9, 9, 6, 8, 7], // 9
  823: [8, 5, 3, 7, 2, 3, 7, 6, 4], // 9
  824: [5, 8, 6, 9, 4, 7, 6, 4, 5], // 8
  825: [9, 6, 1, 9, 7, 6, 5, 4, 1], // 9
  826: [9, 1, 9, 4, 1, 7, 9, 5, 6], // 8
  827: [7, 7, 7, 2, 9, 3, 2, 9, 8], // 8
  828: [2, 8, 8, 6, 3, 2, 6, 9, 7], // 9
}

const envId = 123987

export default function App() {
  const [remoteScoreInfo, setRemoteScoreInfo] = useState(null)
  const params = useParams()
  const gameNumberParam = params.gameNumber

  const firstDayEver = new Date("06/19/2023");
  const currentDate = new Date();

  const timeDiff = Math.abs(currentDate - firstDayEver) 
  const daysPassed = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const gameNumber = (gameNumberParam) ? parseInt(gameNumberParam) : daysPassed
  const isArchive = gameNumber != daysPassed
  const dateOfArchiveGame = new Date(firstDayEver);
  dateOfArchiveGame.setDate(firstDayEver.getDate() + gameNumber);

  const startingNumbers = games[gameNumber]

  const fetchRemoteScores = () => {
    if (startingNumbers) {
      const requestInfo = {
        headers: {},
        response: true,
        queryStringParameters: {
          envId: envId,
          gameBoard: startingNumbers.join('')
        }
      }
  
      API.get('Scores', `/scores/${gameNumber}`, requestInfo)
      .then((response) => {
        setRemoteScoreInfo(response.data)
      })
      .catch((error) => {
        console.log(error.response)
      });
    }
  }

  useEffect(() => {
    fetchRemoteScores()
  }, []);

  const bgStyle = {
    background: {
        backgroundColor: '#ffffff',
        width: '100%',
        height: '90%'
    },
    archiveControls: {
      height: '10%'
    }
  }

  if (gameNumber <= daysPassed) {
    return <div>
      <div id='background' style={bgStyle.background}> 
        <GameContainer 
          startingNumbers={startingNumbers} 
          gameNumber={gameNumber} 
          remoteScoreInfo={remoteScoreInfo} 
          fetchRemoteScores={fetchRemoteScores}
          isArchive={isArchive}
          dateOfArchiveGame={dateOfArchiveGame}
        />
      </div>
      <div style={bgStyle.archiveControls}>
       {
          isArchive && 
          <ArchiveControls/>
        }
      </div>
    </div>;
  } else {
    return <TooEarlyForPuzzle/>
  }
}